import { inject } from '@angular/core';

import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpStatusCode,
} from '@angular/common/http';

import { catchError, throwError } from 'rxjs';

import { AuthService, JWTService } from '../services';
import { Router } from '@angular/router';

export const httpTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const jwt = inject(JWTService);
  const auth = inject(AuthService);
  const router = inject(Router);
  const token = jwt.token;

  if (token) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return next(req).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case HttpStatusCode.Unauthorized:
            if (auth.authenticated()) {
              auth.clear();
              router.navigateByUrl('/');
            }
            break;
        }
      }

      return throwError(() => error);
    })
  );
};
