<div
  class="flex flex-col items-center justify-center p-4 landscape:p-8 landscape:justify-start landscape:items-start"
>
  <img class="h-8" src="/assets/images/logo.svg" alt="Reakt360" />
</div>

<div class="p-4 flex flex-col landscape:justify-center landscape:items-center">
  <div
    class="landscape:p-12 landscape:bg-white landscape:rounded-lg landscape:max-w-mobile landscape:w-full landscape:shadow-sm"
  >
    <div class="flex flex-col items-stretch justify-start gap-8">
      <div class="flex flex-row justify-end">
        <a [routerLink]="['/']" title="Cancel" aria-label="Cancel">
          <span class="material-symbols-outlined"> close </span>
        </a>
      </div>

      <h1 class="text-3xl font-normal font-display">Account Deleted</h1>

      <p class="text-sm font-normal">
        Your account has now been removed from our system.
      </p>

      <p class="text-sm font-normal">
        If you wish to use our platform again you would need a new account.
      </p>
    </div>
  </div>
</div>
