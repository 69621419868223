import { Injectable, Injector, inject, signal } from '@angular/core';
import { Observable, catchError, of, switchMap, tap } from 'rxjs';

import { ApiService } from '@features/api';
import { NotificationsService } from '@features/notifications';

import {
  Activation,
  Auth,
  Challenge,
  Login,
  PasswordChange,
  Reset,
  User,
  UserDetails,
  Recovery,
} from '../models';
import { JWTService } from './jwt.service';

@Injectable()
export class AuthService {
  readonly #user = signal<User | null>(null);
  public user = this.#user.asReadonly();

  readonly #authenticated = signal<boolean>(false);
  public authenticated = this.#authenticated.asReadonly();

  protected api = inject(ApiService);
  protected jwt = inject(JWTService);
  protected injector = inject(Injector);

  activate(payload: Activation): Observable<User> {
    return this.api.post<Auth>('/user/complete-account', payload).pipe(
      tap((auth) => this.jwt.save(auth.token)),
      switchMap(() => this.populate())
    );
  }

  recover(payload: Recovery): Observable<void> {
    return this.api.post<void>('/user/forgot-password', payload);
  }

  reset(payload: Reset): Observable<void> {
    return this.api.post('/user/forgot-password-reset', payload);
  }

  login(payload: Login): Observable<Challenge> {
    return this.api.post<Challenge>('/user/login', payload);
  }

  resend2fa(token: string): Observable<Challenge> {
    return this.api.post<Challenge>('/user/resend-code', {
      login_token: token,
    });
  }

  confirm2fa(token: string, code: string): Observable<User> {
    return this.api
      .post<Auth>('/user/validate-two-factor', {
        login_token: token,
        code,
      })
      .pipe(
        tap((auth) => this.jwt.save(auth.token)),
        switchMap(() => this.populate())
      );
  }

  populate(): Observable<User> {
    return this.api.get<User>('/user').pipe(
      tap((user) => {
        this.#user.set(user);
        this.#authenticated.set(true);
      }),
      catchError((error) => {
        this.jwt.clear();
        return of(error);
      })
    );
  }

  delete(): Observable<void> {
    return this.api.delete<void>('/user').pipe(tap(() => this.clear()));
  }

  logout(): Observable<void> {
    const notifications = this.injector.get(NotificationsService);
    return notifications
      .unregister()
      .pipe(
        switchMap(() =>
          this.api.post<void>('/user/logout').pipe(tap(() => this.clear()))
        )
      );
  }

  update(payload: UserDetails): Observable<User> {
    return this.api
      .post<User>('/user', payload)
      .pipe(tap((user) => this.#user.set(user)));
  }

  changePassword(payload: PasswordChange): Observable<void> {
    return this.api.post<void>('/user/password-reset', payload);
  }

  changeStatus(status: number): Observable<User> {
    return this.api
      .get<User>('/user/toggle-status', { status_type_id: status })
      .pipe(tap((user) => this.#user.set(user)));
  }

  clear(): void {
    this.jwt.clear();
    this.#user.set(null);
    this.#authenticated.set(false);
  }
}
