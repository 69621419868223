<div class="flex flex-col items-center justify-center p-8 landscape:justify-start landscape:items-start">
  <img class="h-8" src="/assets/images/logo.svg" alt="Reakt360" />
</div>

<div class="p-4 flex flex-col landscape:justify-center landscape:items-center">
  <div
    class="landscape:p-12 landscape:bg-white landscape:rounded-lg landscape:max-w-mobile landscape:w-full landscape:shadow-sm portrait:h-full">
    <form class="flex flex-col items-stretch justify-start gap-8 portrait:h-full" [formGroup]="form"
      (ngSubmit)="submit()">
      <legend class="text-3xl font-normal font-display">
        Enter A New Password
      </legend>

      <p class="text-sm font-normal">
        Please add a password below. Your password must contain a minimum of 8
        characters and contain at least 1 number, 1 uppercase character, 1
        lowercase character and 1 special character.
      </p>

      <fieldset [disabled]="loading()" class="flex flex-col gap-8 items-stretch flex-1">
        <div>
          <label class="sr-only" for="password">Password</label>
          <app-password-form-field id="password" class="w-full" formControlName="password"
            placeholder="Enter a new password" autocomplete="new-password"></app-password-form-field>

          <app-password-strength-indicator [control]="form.get('password')"></app-password-strength-indicator>

          <app-form-error [control]="form.get('password')" [errors]="{
              required: 'Password is required.',
              uppercase: 'Password must contain at least 1 uppercase letter.',
              lowercase: 'Password must contain at least 1 lowercase letter.',
              digit: 'Password must contain at least 1 number.',
              special: 'Password must contain at least 1 special character.',
              minlength: 'Password must be at least 8 characters long.'
            }"></app-form-error>
        </div>

        <div>
          <label class="sr-only" for="password_confirmation">Confirm Password</label>
          <app-password-form-field id="password_confirmation" class="w-full" formControlName="password_confirmation"
            placeholder="Confirm your password" autocomplete="new-password"></app-password-form-field>

          <app-form-error [control]="form.get('password_confirmation')" [errors]="{
              required: 'Password confirmation is required.',
              mismatch: 'Passwords must match.'
            }"></app-form-error>
        </div>

        <div class="flex flex-col items-stretch flex-1 justify-end">
          @if(loading()) {
          <app-spinner></app-spinner>
          } @else {
          <input [disabled]="form.invalid" class="filled-button" type="submit" value="Save Password" />
          }
        </div>
      </fieldset>
    </form>
  </div>
</div>