import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, provideRouter } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { SpinnerModule } from '@features/ui/components/spinner';
import { PasswordFormFieldModule } from '@features/ui/components/password-form-field';
import { FormErrorModule } from '@features/ui/components/form-error';
import { BackButtonModule } from '@features/ui/components/back-button';

import {
  ChallengePageComponent,
  LoginPageComponent,
  ActivatePageComponent,
  RecoverPageComponent,
  ResetPageComponent,
  ResetPasswordPageComponent,
  LockedPageComponent,
  DeletedPageComponent,
  EmailChangedPageComponent,
} from './pages';
import { AuthService, JWTService } from './services';
import { routes } from './auth.routes';

function initializeAuth(auth: AuthService, jwt: JWTService) {
  return () =>
    jwt.token ? firstValueFrom(auth.populate()) : Promise.resolve();
}

@NgModule({
  declarations: [
    LoginPageComponent,
    ChallengePageComponent,
    ActivatePageComponent,
    RecoverPageComponent,
    ResetPageComponent,
    ResetPasswordPageComponent,
    DeletedPageComponent,
    LockedPageComponent,
    EmailChangedPageComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SpinnerModule,
    PasswordFormFieldModule,
    FormErrorModule,
    BackButtonModule,
    RouterModule,
  ],
  exports: [],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAuth,
      deps: [AuthService, JWTService],
      multi: true,
    },
    AuthService,
    JWTService,
    provideRouter(routes),
  ],
})
export class AuthModule {}
