import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-changed-page',
  templateUrl: './email-changed-page.component.html',
  styleUrls: ['./email-changed-page.component.scss'],
})
export class EmailChangedPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
