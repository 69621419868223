<div class="flex flex-col items-center justify-center p-4 landscape:p-8 landscape:justify-start landscape:items-start">
  <img class="h-8" src="/assets/images/logo.svg" alt="Reakt360" />
</div>

<div class="p-4 flex flex-col landscape:justify-center landscape:items-center">
  <div
    class="landscape:p-12 landscape:bg-white landscape:rounded-lg landscape:max-w-mobile landscape:w-full landscape:shadow-sm">
    <div class="flex flex-col items-stretch justify-start gap-8">

      <h1 class="text-3xl font-normal font-display">Email Changed</h1>

      <p class="text-sm font-normal">
        Your email address has now been changed. You must now log in using your new credentials.
      </p>

      <a class="filled-button" [routerLink]="['/login']" title="To login">Login</a>
    </div>
  </div>
</div>