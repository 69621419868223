import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Component, effect, inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MessagePayload } from '@angular/fire/messaging';

import {
  catchError,
  EMPTY,
  exhaustMap,
  finalize,
  from,
  merge,
  Subject,
  switchMap,
  take,
  takeUntil,
  timer,
} from 'rxjs';

import { AuthService } from '@features/auth';
import { CallsService } from '@features/common';
import { SnackBar } from '@features/ui/components/snack-bar';

import { NotificationsService } from '../../services';
import {
  IncomingCallData,
  IncomingCallDialogComponent,
} from '../incoming-call-dialog';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnDestroy {
  #unsubscribe$ = new Subject<void>();

  #timeout = 30000;

  protected auth = inject(AuthService);
  protected calls = inject(CallsService);
  protected dialog = inject(Dialog);
  protected snackbar = inject(SnackBar);
  protected router = inject(Router);
  protected dialogRef?: DialogRef<
    IncomingCallData,
    IncomingCallDialogComponent
  >;
  protected notifications = inject(NotificationsService);
  protected ringtone = new Audio('/assets/ringtone.mp3');

  constructor() {
    effect(() => {
      const authenticated = this.auth.authenticated();

      if (authenticated) {
        this.registerForPushNotifications();
      }
    });
  }

  protected unregisterForPushNotifications(): void {
    this.notifications
      .unregister()
      .pipe(
        takeUntil(this.#unsubscribe$),
        catchError(() => EMPTY)
      )
      .subscribe();
  }

  protected registerForPushNotifications(): void {
    this.notifications
      .register((payload) => this.handleNotification(payload))
      .pipe(takeUntil(this.#unsubscribe$))
      .subscribe();
  }

  protected handleNotification(payload: MessagePayload) {
    const id = payload.data?.['callId'];

    if (!id) {
      return;
    }

    const caller = payload.data?.['callerName'];

    this.#unsubscribe$.next();

    switch (payload.data?.['type']) {
      case 'call':
        /**
         * Call is incoming, show dialog to answer or reject.
         *
         * Hide the dialog if the call is answered, rejected or
         * the timeout is reached.
         */
        this.dialogRef = this.dialog.open(IncomingCallDialogComponent, {
          disableClose: true,
          data: {
            caller,
            answer: () => {
              this.dialogRef?.close();
              from(this.router.navigate(['/call', id])).subscribe();
            },
            reject: () => {
              this.dialogRef?.close();
              this.calls.reject(id).subscribe();
            },
          },
        });
        this.ringtone.currentTime = 0;
        this.ringtone.loop = true;

        from(this.ringtone.play())
          .pipe(
            catchError(() => {
              /**
               * This is needed because some browsers do not allow
               * autoplaying audio without user interaction.
               */
              return EMPTY;
            }),
            switchMap(() =>
              merge(
                timer(this.#timeout).pipe(
                  exhaustMap(() => this.calls.reject(id))
                ),
                this.dialogRef?.closed ?? EMPTY,
                this.#unsubscribe$
              ).pipe(take(1))
            ),
            finalize(() => {
              this.dialogRef?.close();
              this.ringtone.pause();
            })
          )
          .subscribe();

        break;

      case 'call-cancelled':
        /**
         * Call was missed by the user.
         */
        this.snackbar.add(
          {
            message: `You missed a call from ${caller}.`,
            type: 'error',
            icon: 'phone_missed',
            important: true,
          },
          () => this.router.navigate(['/history', id])
        );

        break;

      case 'call-answered':
        /**
         * Call was answered by the user on another device.
         */
        this.dialogRef?.close();
        break;

      case 'call-rejected':
        /**
         * Call was rejected by the user being called.
         * Show a snackbar message and redirect to the home page.
         */
        this.snackbar.add({
          message: `Your call to ${caller} was rejected.`,
          type: 'error',
          icon: 'call_missed_outgoing',
        });

        this.router.navigateByUrl('/');

        break;

      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.#unsubscribe$.next();
    this.#unsubscribe$.complete();
  }
}
